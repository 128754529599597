<template>
  <div class="m-3">
    <h2>Redtail Authorization</h2>
    <p>
      Enter your Redtail admin credentials to connect your Redtail account to
      Plain Wealth.
    </p>
    <p>We DO NOT store your credentials.</p>
    <hr />
    <b-form-group
      id="username-group"
      label="Enter your Redtail admin username."
      label-for="username-input"
    >
      <b-form-input
        id="username-input"
        placeholder="redtail username"
        v-model="username"
        type="text"
        autocomplete="off"
        trim
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="password-group"
      label="Enter your Redtail admin password."
      label-for="password-input"
    >
      <b-form-input
        id="password-input"
        placeholder="redtail password"
        v-model="password"
        type="text"
        autocomplete="off"
        trim
      ></b-form-input>
    </b-form-group>

    <b-button
      variant="primary"
      block
      @click="setAuthorization"
      class="mb-3"
      :disabled="username.length == 0 || password.length == 0"
      >Connect Redtail<b-spinner small v-if="authorizing"
    /></b-button>
    <p></p>
    <b-button variant="secondary" block @click="checkAuthorization" class="mb-3"
      >{{ authText }}<b-spinner small v-if="checkingAuth"
    /></b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      authorizing: false,
      alreadyAuthorized: false,
      checkingAuth: false,
      authText: "Check Connection",
    };
  },
  methods: {
    async setAuthorization() {
      this.authorizing = true;
      console.log(this.$store.state.JWT)
      try {
        await this.$http.put(
          this.$store.state.apiURL + "/redtail-authorization",
          {
            username: this.username,
            password: this.password,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.username = "";
        this.password = "";
        this.authText = "CONNECTED";
        this.alreadyAuthorized = true;
        this.authorizing = false;
        this.showToast("Succesfully set authorization!", "Success!", "success");
      } catch (error) {
        this.handleError(error);
        this.authorizing = false;
        console.log(error)
      }
    },
    async checkAuthorization() {
      this.checkingAuth = true;
      try {
        // get auth status
        let authStatus = await this.$http.get(
          this.$store.state.apiURL + "/redtail-authorization",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        console.log(authStatus.data);
        if (authStatus.data == "Redtail Authorization is set.") {
          this.alreadyAuthorized = true;
          this.authText = "CONNECTED";
        } else {
          this.authText = "NOT CONNECTED";
        }
        this.checkingAuth = false;
      } catch (error) {
          this.handleError(error);
          this.checkingAuth = false;
          this.authText = "NOT CONNECTED";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss";
</style>